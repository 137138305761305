import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { HttpResponse } from '@angular/common/http';
import { SnackbarService } from './snackbar.service';
import LoginComponent from 'app/shared/dialog/login/login.component';
import RegisterComponent from 'app/shared/dialog/register/register.component';

@Injectable({
  providedIn: 'root'
})
export class LoginDialogService {
  isLoadLogging: boolean = false;
  private loginDialogRef!: MatDialogRef<LoginComponent>;

  constructor(
    private dialog: MatDialog,
    private snackBar: SnackbarService,
    private userService: UserService,
    private storageService: StorageService
  ) { }

  openLoginDialog(): void {
    this.loginDialogRef = this.dialog.open(LoginComponent, {
      // width: '90%',
      // maxWidth: '100vw',
      // maxHeight: '100vh',
    });
    this.loginDialogRef.afterClosed().subscribe(loginForm => {
      if (!loginForm || !loginForm.valid) {
        return;
      }
      this.isLoadLogging = true;
      this.userService.login(loginForm.value).subscribe({
        next: (resp: HttpResponse<any>) => {
          const existUser = resp.body.data;

          this.storageService.saveJsonData('uid', existUser.id);
          this.storageService.saveJsonData('username', existUser.username);
          this.storageService.saveJsonData('avatar', existUser.avatar);

          const token = existUser.token;
          if (token != null) {
            this.storageService.setToken(token);
          }

          const roleID = existUser.role_id;
          if (roleID > 0) {
            this.storageService.saveJsonData('role_id', roleID);
            this.storageService.saveJsonData(this.userService.IS_LOGGED_IN_KEY, true);
            this.userService.setIsLoggedIn(true);
            this.userService.setAvatar(existUser.avatar)
          }
          this.isLoadLogging = false;
        },
        error: err => {
          this.snackBar.open(err.error.msg);
          this.isLoadLogging = false;
        }
      });
    });
  }

  openRegisterDialog(): void {
    const dialogRef = this.dialog.open(RegisterComponent)
    dialogRef.afterClosed().subscribe(registerForm => {
      if (!registerForm || !registerForm.valid) {
        return
      }

      this.userService.register(registerForm.value).subscribe({
        next: (response: HttpResponse<any>) => {
          this.snackBar.open("注册成功，请重新登录")
        },
        error: err => {
          this.snackBar.open(err.error.msg)
        }
      })
    })

    this.loginDialogRef.close()
  }

}
