<mat-card class="card">
    <mat-card-header class="header" color="primary">
        <mat-card-title>会员登录</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <form class="form" [formGroup]="loginForm">
            <mat-form-field class="full-width">
                <mat-label>用户名</mat-label>
                <input matInput formControlName="username" tabindex="1">
                <mat-error *ngIf="loginForm.hasError('required', ['username'])">
                    用户名是必填项                </mat-error>
                <mat-error
                    *ngIf="loginForm.hasError('minlength', ['username']) || loginForm.hasError('maxlength', ['username'])">
                    用户名<strong> 最少3个字符 </strong>
                </mat-error> 
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>密&nbsp;&nbsp;&nbsp;&nbsp;码</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
          tabindex="2">
                <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>  
                <mat-error *ngIf="loginForm.hasError('required', ['password'])">
                    密码是必填项                </mat-error>
                <mat-error *ngIf="loginForm.hasError('minlength', ['password'])">
                    密码最少6位字符
                </mat-error>
            </mat-form-field>

            <div class="captcha-container">
                <mat-form-field>
                    <mat-label>验证码</mat-label>
                    <input matInput formControlName="answer" (keydown.enter)="handleLogin()" #captchaInput tabindex="3">
                    <mat-error *ngIf="loginForm.hasError('required', ['answer'])">
                        验证码是必填项
                    </mat-error>
                    <mat-error
                        *ngIf="loginForm.hasError('minlength', ['answer']) || loginForm.hasError('maxlength', ['answer'])">
                        验证码<strong> 最少6位数子</strong>
                    </mat-error>
                </mat-form-field>
                <img [src]="captchaImage" alt="验证码图片" (click)="refreshCaptcha()" class="captcha-image">
            </div>
            <mat-checkbox class="example-margin" [checked]="rememberMeFlag" (click)="rememberMe()"> 记住账号</mat-checkbox>
        </form>
        <mat-divider></mat-divider>

    </mat-card-content>

    <mat-card-actions>
        <div class="submit">
            <button mat-button mat-dialog-close>退出</button>
            <button mat-flat-button color="primary" tabindex="4" [disabled]="!loginForm.valid" [mat-dialog-close]="loginForm">登录</button>
        </div>
        <mat-divider></mat-divider>
        <div class="submit">
            <p class="subtitle">还没有账号？ <span (click)="handleRegister()" style="color:white">注册</span></p>
            <mat-error *ngIf="!loginFlag">登录失败</mat-error>
        </div>
    </mat-card-actions>
</mat-card>