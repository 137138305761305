import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatInput, MatInputModule } from '@angular/material/input';
import { UserService } from '../../../services/user.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { StorageService } from '../../../services/storage.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from 'app/shared/material.module';

@Component({
  selector: 'app-register',
  standalone: true ,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export default class RegisterComponent {
  @ViewChild('passwordInput') passwordInput!: MatInput;
  @ViewChild('inviteCodeInput') inviteCodeInput!: ElementRef;

  registerForm!: FormGroup
  hide: boolean = true
  captchaImage: string = ''
  captchaID: string = ''

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private snackBar: SnackbarService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.storageService.clearToken()

    this.registerForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      // repassword: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      email: ['', [Validators.email]],
      invitecode: ['DqSedmIB3m9kbAvLSIU', [Validators.required, Validators.minLength(6)]],
      answer: ['', [Validators.required, Validators.minLength(6)]],
      captcha_id: ['']
    })

    this.refreshCaptcha()
  }

  handleSubmit() {

  }
  handleRegister = () => {
    // 校验失败
    if (!this.registerForm.valid) {
      return
    }
    console.log("register");

  }
  // 刷新验证码的函数
  refreshCaptcha() {
    this.userService.captcha().subscribe({
      next: (data) => {
        console.log(data);

        this.captchaImage = data.image // 将获取到的 base64 编码的图片设置到变量中
        this.captchaID = data.captcha_id

        this.registerForm.patchValue({
          captcha_id: data.captcha_id
        });

        // this.loginForm.value.captcha = data.captcha_id
        console.log("loginForm:", this.captchaID);
        console.log("loginForm:", this.registerForm.value);  // captcha_id这里有值
      },
      error: (err) => {
        console.error('获取验证码失败:', err.error.msg);
      }
    }

    );
  }


  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault(); // 阻止默认的 Tab 键行为
      this.inviteCodeInput.nativeElement.focus(); // 将焦点移到验证码输入框
    }
  }
}
