import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { HttpResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MaterialModule } from '../../../shared/material.module';
import { StorageService } from '../../../services/storage.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { UserService } from '../../../services/user.service';
// import { RoleService } from '../../../services/role.service';
import { LoginDialogService } from '../../../services/login-dialog.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [MaterialModule, FormsModule, ReactiveFormsModule, CommonModule, RouterLink],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export default class LoginComponent {
  @ViewChild('passwordInput') passwordInput!: MatInput;
  @ViewChild('captchaInput') captchaInput!: ElementRef;

  loginForm!: FormGroup;
  loginFlag: boolean = true;
  rememberMeFlag: boolean = true;
  hide: boolean = true;
  captchaImage: string = ''; // 用于存储验证码图片的 base64 编码
  captchaID: string = ''
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private storageService: StorageService,
    private snackBar: SnackbarService,
    private userService: UserService,
    // private roleService: RoleService,
    private loginDialog: LoginDialogService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      answer: ['', [Validators.required, Validators.minLength(6)]],
      captcha_id: ['']
    })
    this.checkToken()
    this.refreshCaptcha()
  }

  // 发送token，判断是否合法（在拦截器中获取token发送到服务器，给服务器的中间件认证，合法就跳转到后台
  checkToken = () => {
    // this.loginService.token().subscribe((resp: HttpResponse<any>) => {
    //   // token合法，转到后台
    //   if (resp.body.code === 20002) {
    //     this.router.navigate(['/backend'])
    //   }
    // })
  }

  rememberMe = () => {
    // 记住rememberMeFlag, 如果为true 还要记住用户名，暂时不考虑
    this.rememberMeFlag = !this.rememberMeFlag;
    // this.storageService.saveJsonData("username", "test");
    this.storageService.saveJsonData("rememberMeFlag", this.rememberMeFlag);
  }

  handleLogin = () => {

  }

  // 刷新验证码的函数
  refreshCaptcha() {
    this.userService.captcha().subscribe({
      next: (data) => {
        // console.log(data);
        this.captchaImage = data.image // 将获取到的 base64 编码的图片设置到变量中
        this.captchaID = data.captcha_id

        this.loginForm.patchValue({
          captcha_id: data.captcha_id
        });

        // this.loginForm.value.captcha = data.captcha_id
        // console.log("loginForm:", this.captchaID);
        // console.log("loginForm:", this.loginForm.value);  // captcha_id这里有值
      },
      error: (err) => {
        this.snackBar.open("获取验证码失败")
      }
    }

    );
  }

  handleRegister() {
    this.loginDialog.openRegisterDialog();  
  }
}
