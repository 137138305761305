<mat-card class="card">
  <mat-card-header class="header" color="primary">
    <mat-card-title>会员注册</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form class="form" [formGroup]="registerForm">
      <mat-form-field class="full-width">
        <mat-label>用户名</mat-label>
        <input matInput formControlName="username" />
        <mat-error *ngIf="registerForm.hasError('required', ['username'])">
          用户名是必填项
        </mat-error>
        <mat-error
          *ngIf="
            registerForm.hasError('minlength', ['username']) ||
            registerForm.hasError('maxlength', ['username'])
          "
        >
          用户名<strong> 最小长度为 3 或最大长度为 20</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" />
        <mat-error *ngIf="registerForm.hasError('email', ['email'])">
          无效的邮箱地址
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>密码</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          #passwordInput
          (keydown)="handleKeyPress($event)"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
        <mat-error *ngIf="registerForm.hasError('required', ['password'])">
          密码是必填项
        </mat-error>
        <mat-error *ngIf="registerForm.hasError('minlength', ['password'])">
          密码最小长度为 6 个字符
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width">
        <mat-label>邀请码</mat-label>
        <input matInput formControlName="invitecode" #inviteCodeInput />
        <mat-error *ngIf="registerForm.hasError('required', ['invitecode'])">
          邀请码是必填项
        </mat-error>
        <mat-error *ngIf="registerForm.hasError('minlength', ['invitecode'])">
          邀请码<strong> 最小长度为 6</strong>
        </mat-error>
      </mat-form-field>

      <div class="captcha-container">
        <mat-form-field class="full-width">
          <mat-label>验证码</mat-label>
          <input
            matInput
            formControlName="answer"
            (keydown.enter)="handleRegister()"
          />
          <mat-error *ngIf="registerForm.hasError('required', ['answer'])">
            验证码是必填项
          </mat-error>
          <mat-error *ngIf="registerForm.hasError('minlength', ['answer'])">
            验证码<strong> 最小长度为 6</strong>
          </mat-error>
        </mat-form-field>
        <img
          [src]="captchaImage"
          alt="验证码图片"
          (click)="refreshCaptcha()"
          class="captcha-image"
        />
      </div>
    </form>
    <mat-divider></mat-divider>
  </mat-card-content>

  <mat-card-actions>
    <div class="submit">
      <button mat-button mat-dialog-close>退出</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="!registerForm.valid"
        [mat-dialog-close]="registerForm"
      >
        注册
      </button>
    </div>
  </mat-card-actions>
</mat-card>
